import React, { useCallback } from 'react';
import { FilesDropzone } from '../../../components';
import { useDirsActions } from '../../dirs/hooks';
import { useCurrentDir } from '../../ui/hooks';
import { useFilesActions } from '../hooks';
import { v4 as uuid } from 'uuid';

function createDirectoryTree(directories: any[] = [], files: any): any {
  return Array.from(directories)
    .filter(item => Array.isArray(item))
    .map(([item, children = []]) => ({
      title: item.name,
      name: item.name,
      path: item.path,
      files: files.filter((file: any) => {
        const filePath = file.path.replace(`/${file.name}`, '');

        return filePath === item.path;
      }),
      children: createDirectoryTree(children, files)
    }));
}

const FileUploaderDropzone: React.FC = ({ children }) => {
  const { createDir } = useDirsActions();
  const { createFile, addToUploadQueue } = useFilesActions();
  const { currentDirId } = useCurrentDir();

  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedItems) => {
      const queuedFiles = acceptedFiles.map(file => {
        // @ts-ignore
        file.id = uuid();
        return file;
      });

      addToUploadQueue(queuedFiles);

      const directories = createDirectoryTree(rejectedItems, queuedFiles);
      directories.map((dir: any) =>
        createDir({
          ...dir,
          parent: currentDirId
        })
      );

      // @ts-ignore
      const rootFiles = queuedFiles.filter(file => file.name === file.path);
      rootFiles.map((file: any) =>
        createFile({
          file,
          dirId: currentDirId
        })
      );
    },
    [currentDirId, addToUploadQueue, createDir, createFile]
  );

  return <FilesDropzone onDrop={handleDrop}>{children}</FilesDropzone>;
};

export { FileUploaderDropzone };
