import messages from './messages.json';
import navigation from './navigation.json';
import global from './global.json';
import users from './users.json';
import myDisk from './myDisk.json';
import validation from './validation.json';

export default {
  global,
  users,
  messages,
  navigation,
  myDisk,
  validation
};
