import React, { Suspense, useEffect, useState } from 'react';
import clsx from 'clsx';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Typography, Hidden } from '@material-ui/core';
import { NavBar, TopBar, Modals } from './components';
import { UploadingList } from '../../modules/files/components/UploadingList';
import { FileUploaderDropzone } from '../../modules/files/components/FileUploaderDropzone';
import { GatewayProvider, GatewayDest } from 'react-gateway';
import { useDirsActions } from '../../modules/dirs/hooks';
import { useFilesActions } from '../../modules/files/hooks';
import manifest from '../../../package.json';
import {
  DashboardViewContextProvider,
  useDashboardView
} from './DashboardViewContext';
import TestWarning from '../../components/TestWarning/TestWarning';

const navbarWidth = '250px';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {},
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    marginTop: 64
  },
  navBar: {
    zIndex: 3,
    width: navbarWidth,
    minWidth: navbarWidth,
    flex: '0 0 auto',
    transition: 'transform .5s ease',
    paddingBottom: theme.spacing(4)
  },
  navBarFullScreen: {
    transform: 'translateX(-100%)'
  },
  content: {
    position: 'relative',
    overflowY: 'auto',
    flex: '1 1 auto',
    transition: 'margin .5s ease',
    [theme.breakpoints.up('lg')]: {
      maxWidth: `calc(100vw - ${navbarWidth})`,
      marginLeft: navbarWidth
    }
  },
  contentFullScreen: {
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
      marginLeft: 0
    }
  },
  appVersion: {
    position: 'fixed',
    width: '240px',
    padding: '8px 16px',
    left: '0',
    bottom: '0',
    zIndex: 50,
    backgroundColor: '#fff'
  }
}));

const Dashboard = props => {
  const { route } = props;
  const { mode } = useDashboardView();
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const { fetchDirs } = useDirsActions();
  const { fetchFiles } = useFilesActions();

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  useEffect(() => {
    fetchDirs();
    fetchFiles();
    // eslint-disable-next-line
  }, []);

  return (
    <FileUploaderDropzone>
      <GatewayProvider>
        <div className={classes.root}>
          <TopBar
            className={classes.topBar}
            onOpenNavBarMobile={handleNavBarMobileOpen}
            showLocaleSwitcher={mode === 'default'}
            showUserActions={mode === 'default'}
          />
          <div className={clsx(classes.container, 'custom-scrollbar')}>
            <NavBar
              className={clsx(classes.navBar, {
                [classes.navBarFullScreen]: mode === 'fullScreen'
              })}
              onMobileClose={handleNavBarMobileClose}
              openMobile={openNavBarMobile}
            />
            <main
              className={clsx(classes.content, {
                [classes.contentFullScreen]: mode === 'fullScreen'
              })}>
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(route.routes)}
              </Suspense>
              <Hidden mdDown>
                <Typography className={classes.appVersion} variant="caption">
                  {manifest.appName} v{manifest.version}
                </Typography>
              </Hidden>
            </main>
          </div>
          <Modals />
          <GatewayDest name="footer" />
        </div>
      </GatewayProvider>
      <UploadingList />
      <TestWarning />
    </FileUploaderDropzone>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

const DashboardWithContext = props => (
  <DashboardViewContextProvider>
    <Dashboard {...props} />
  </DashboardViewContextProvider>
);

export default DashboardWithContext;
