import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import config from 'config/api';
import { Store } from 'redux';
import { logout, refreshToken } from '../modules/auth/actions';
import i18n from './translation';

const instance = axios.create({
  baseURL: config.url
});

let store: Nullable<Store> = null;

export function injectStore(reference: Store) {
  store = reference;
}

instance.interceptors.request.use(config => {
  let state;

  if (store && (state = store.getState())) {
    if (state.auth.accessToken && config.url && config.url.includes('api/')) {
      config.headers['Authorization'] = `Bearer ${state.auth.accessToken}`;
    }

    if (state.auth.actingAs !== null) {
      config.headers['X-Switch-User'] = String(
        state.auth.actingAs
      ).toLowerCase();
    }
  }

  if (i18n.language && !config.headers['Accept-Language']) {
    config.headers['Accept-Language'] = i18n.language;
  }

  return config;
});

instance.interceptors.response.use(response => response.data);

let isRefreshingToken = false;
let subscribers: any = [];

const flushSubscribersQueue = (token: string) => {
  subscribers = subscribers.filter((callback: any) => {
    callback(token);
    return false;
  });
};

instance.interceptors.response.use(
  response => response,
  error => {
    // Reject promise if usual error
    if (!error.response || error.response.status !== 401) {
      return Promise.reject(error);
    }

    const responseError = get(error, 'response.data.error', '');
    if (responseError !== 'invalid_grant') {
      return Promise.reject(error);
    }

    if (!store) {
      return Promise.reject(error);
    }

    if (isRefreshingToken) {
      return new Promise(resolve => {
        subscribers.push((accessToken: string) => {
          error.config.headers.Authorization = `Bearer ${accessToken}`;
          resolve(instance(error.config));
        });
      });
    }

    isRefreshingToken = true;

    const state = store.getState();
    return instance
      .post('oauth/v2/token', {
        refresh_token: state.auth.refreshToken,
        client_id: config.clientId,
        client_secret: config.clientSecret,
        grant_type: 'refresh_token'
      })
      .then(({ access_token, refresh_token }: any) => {
        error.response.config.headers['Authorization'] =
          'Bearer ' + access_token;

        if (store) {
          store.dispatch(
            refreshToken.success({
              access_token,
              refresh_token
            })
          );
        }

        flushSubscribersQueue(access_token);

        return instance(error.response.config);
      })
      .catch(error => {
        if (store) {
          store.dispatch(logout.trigger());
        }

        return Promise.reject(error);
      })
      .finally(() => {
        isRefreshingToken = false;
      });
  }
);

export default instance;
