import React from 'react';
import http from 'utils/http';
import { FieldProps } from './types';
import { FormControl, IconButton, InputLabel } from '@material-ui/core';
import { Select } from 'components';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'theme';
import { useTranslation } from 'utils/translation';

const useStyles = makeStyles((theme: Theme) => ({
  removeButton: {
    position: 'absolute',
    right: theme.spacing(1)
  }
}));

const RemoveOption: React.FC<any> = ({ value, fieldName, onRemove }) => {
  const classes = useStyles();

  const handleClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    http.delete(`api/intelligence/choice/${fieldName}/${value}`);
    onRemove(value);

    return false;
  };

  return (
    <IconButton className={classes.removeButton} onClick={handleClick}>
      <CloseIcon />
    </IconButton>
  );
};

const SelectCreatable: React.FC<FieldProps> = ({
  fieldName,
  value,
  errorMessage,
  name,
  multiple,
  form,
  settings = {}
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = React.useState<any[]>(
    Array.from(settings.options || [])
  );

  const onRemove = React.useCallback(
    value => {
      const nextOptions = options.filter((item: any) => item.value !== value);

      setOptions(nextOptions);
    },
    [options, setOptions]
  );

  const handleChange = (newValue: any, actionMeta: any) => {
    if (newValue && 'create-option' === actionMeta.action) {
      const values = Array.isArray(newValue) ? newValue : [newValue];
      const newOption = values.filter((item: any) => item.__isNew__)[0];
      if (newOption) {
        setOptions(prev => [
          ...prev,
          {
            label: newOption.label,
            value: newOption.value
          }
        ]);
      }
    }

    let nextValue;
    if (newValue) {
      nextValue = multiple
        ? Array.from(newValue || []).map((item: any) => item.value)
        : newValue.value;
    } else {
      nextValue = multiple ? [] : null;
    }

    form.setFieldValue(name, nextValue, false);
  };

  const selectedValues = multiple
    ? Array.from(options || []).filter((option: any) => {
        return Array.isArray(value) && value.includes(option.value);
      })
    : Array.from(options || []).filter(option => option.value === value);

  const sortedOptions = options
    .sort((a, b) => a.label.localeCompare(b.label))
    .map(option => ({
      ...option,
      fieldName,
      onRemove,
      rightSlot: RemoveOption
    }));

  return (
    <FormControl fullWidth variant="outlined" error={!!errorMessage}>
      {errorMessage && <InputLabel>{errorMessage}</InputLabel>}
      <Select
        multiple={multiple}
        options={sortedOptions}
        value={selectedValues}
        placeholder={t('Select')}
        onChange={handleChange}
        creatable
        choicesUrl={`/api/intelligence/choice/${fieldName}`}
      />
    </FormControl>
  );
};

export { SelectCreatable };
