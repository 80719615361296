import React, { useCallback, useEffect } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { isDir, DirResource } from '../../../../../modules/resources';
import {
  useDirQuery,
  useDirsActions,
  useDirsFormState,
  useDirSiblings
} from '../../../../../modules/dirs/hooks';
import { FormikBag } from 'formik';
import { Loader, ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';
import { DirectoryUpdateForm } from './DirectoryUpdateForm';
import { RootState } from '../../../../../store';

interface Props {
  open: boolean;
  onClose: () => void;
  resource: DirResource;
}

type FormValues = {
  title: string | object;
};

export const DirectoryFormModal = ({ resource, open, onClose }: Props) => {
  const dispatch = useDispatch();
  const fullScreen = useShouldModalBeFullscreen();
  const { pathUpdateDir, clearErrors } = useDirsActions();
  const { didUpdate, errors } = useDirsFormState();
  const { data: dir = null, isLoading, clear } = useDirQuery(resource.id);
  const hasTranslatableDirectories = useSelector(
    (state: RootState) => state.ui.app.features.translatableDirectories
  );

  const siblings = useDirSiblings(resource.id);
  let form = React.useRef<FormikBag<any, FormValues>>();

  useEffect(() => {
    if (didUpdate) {
      onClose();
    }

    if (errors && form.current) {
      form.current.setErrors(errors);
      form.current.setSubmitting(false);
    }

    return () => {
      clearErrors();
      clear();
    };
    // eslint-disable-next-line
  }, [didUpdate, errors]);

  const onSubmit = useCallback(
    (values: FormValues, formBag) => {
      form.current = formBag;

      if (isDir(resource)) {
        pathUpdateDir({
          id: resource.id,
          title: values.title
        });
      }
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const validate = (values: FormValues) => {
    if (
      siblings &&
      siblings.some(
        item =>
          typeof values.title === 'string' && item.title === values.title.trim()
      )
    ) {
      return {
        title: 'This name already exists'
      };
    }
  };

  const getInitialValues = () => {
    if (!dir) {
      return {};
    }

    if (hasTranslatableDirectories) {
      return {
        title: ['nl', 'fr', 'en'].reduce(
          (prev: { [key: string]: string }, lang) => {
            prev[lang] = get(dir, `translations.${lang}.title`);
            return prev;
          },
          {}
        )
      };
    }

    return {
      title: dir.title
    };
  };

  return (
    <Dialog
      aria-labelledby="directory-form-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm">
      <DialogContent>
        <ModalClose onClose={onClose} />
        {dir && !isLoading ? (
          <DirectoryUpdateForm
            onCancel={onClose}
            onSubmit={onSubmit}
            initialValues={getInitialValues()}
          />
        ) : (
          <Loader />
        )}
      </DialogContent>
    </Dialog>
  );
};
