import React from 'react';
import {
  createStyles,
  Dialog,
  DialogContent,
  makeStyles
} from '@material-ui/core';
import { ModalClose } from 'components';
import { DirResource } from 'modules/resources';
import { useDirsActions } from 'modules/dirs/hooks';
import { MoveForm } from './MoveForm';
import { useTranslation } from 'utils/translation';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';
import { Dir } from '../../../../../modules/dirs';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      height: '70vh'
    }
  })
);

interface Props {
  open: boolean;
  onClose: () => void;
  dir: DirResource;
}

export const MoveDirectoryFormModal = ({ dir, open, onClose }: Props) => {
  const classes = useStyles();
  const fullScreen = useShouldModalBeFullscreen();
  const { t } = useTranslation('myDisk');
  const { moveDir } = useDirsActions();

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = (dirId: number) => {
    moveDir({
      id: dir.id,
      parent: dirId
    });
    onClose();
  };

  const filterDirectories = React.useCallback(
    (node: Pick<Dir, 'id'>) => {
      return node.id !== dir.id;
    },
    [dir]
  );

  return (
    <Dialog
      aria-labelledby="move-directory-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm">
      <DialogContent className={classes.content}>
        <ModalClose onClose={onClose} />
        <MoveForm
          title={t('Move directory')}
          filter={filterDirectories}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};
