import React, { useEffect } from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'utils/translation';
import { dirsActions } from '../../../../../modules/dirs/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { applyFilters } from '../../../../../core';
import dirsConfig from '../../../../../config/dirs';
import DirectoryTitleField from '../DirectoryTitleField';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0
  }
}));

export interface FormValues {
  title: string;
  directoryType: string;
  [key: string]: string;
}

interface OtherProps {
  onCancel: () => void;
  onSubmit: (values: FormValues) => void;
  initialValues?: Partial<FormValues>;
  isEdit?: boolean;
}

const BaseDirectoryForm = (props: OtherProps & FormikProps<FormValues>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['dirs', 'validation']);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,

    isEdit,
    onCancel
  } = props;

  const {
    didCreate,
    didUpdate,
    isSubmitting,
    errors: stateErrors
  } = useSelector((state: RootState) => state.dirs);

  useEffect(() => {
    if (didCreate || didUpdate) {
      onCancel && onCancel();
    }
    // eslint-disable-next-line
  }, [didCreate, didUpdate]);

  useEffect(() => {
    return () => {
      dispatch(dirsActions.clearErrors());
    };
    // eslint-disable-next-line
  }, []);

  const getError = (field: keyof FormValues) => {
    if (touched[field] && errors[field]) {
      return errors[field];
    }

    if (stateErrors && typeof stateErrors[field] === 'string') {
      return stateErrors[field];
    }
  };

  const availableTypes = applyFilters(
    'available_directory_types',
    dirsConfig.types
  );

  return (
    <form onSubmit={handleSubmit}>
      <Typography align="center" gutterBottom variant="h3">
        {t(isEdit ? 'Update Directory' : 'Create Directory')}
      </Typography>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <DirectoryTitleField
            getError={getError}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl error={Boolean(getError('directoryType'))} fullWidth>
            <InputLabel>{t('Directory Type')}</InputLabel>
            <Select
              name="directoryType"
              disableUnderline
              value={values.directoryType}
              onChange={handleChange}
              variant="outlined">
              {Object.keys(availableTypes).map(type => (
                <MenuItem key={type} value={type}>
                  {t(availableTypes[type])}
                </MenuItem>
              ))}
            </Select>
            {getError('directoryType') && (
              <FormHelperText>
                {t(`validation:${getError('directoryType')}`)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <CardActions className={classes.actions}>
        <Button onClick={onCancel} variant="contained">
          {t('Cancel')}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting}>
          {isSubmitting ? (
            <CircularProgress size={24} />
          ) : (
            t(isEdit ? 'Save' : 'Create')
          )}
        </Button>
      </CardActions>
    </form>
  );
};

const buildValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required('Required'),
    directoryType: Yup.string().required('Required')
  });
};

export const DirectoryForm = withFormik<OtherProps, FormValues>({
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      title: initialValues.title || '',
      directoryType: initialValues.directoryType || 'general'
    };
  },
  validationSchema: buildValidationSchema,
  handleSubmit: (values, bag) => {
    const { onSubmit } = bag.props;
    onSubmit(values);
  }
})(BaseDirectoryForm);

DirectoryForm.defaultProps = {
  isEdit: false
};
