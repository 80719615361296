import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Typography } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import { Link } from 'react-router-dom';
import { useCurrentDir } from '../../../../modules/ui/hooks';
import { useSingleDir } from '../../../../modules/dirs/hooks';
import { useTranslation } from 'utils/translation';
import { Dir } from '../../../../modules/dirs';
import config from 'config/app';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    link: {
      color: 'inherit',
      display: 'flex'
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20
    }
  })
);

export default function DiskBreadcrumbs() {
  const classes = useStyles();
  const { t } = useTranslation('navigation');
  const { currentDirId } = useCurrentDir();
  const { dir } = useSingleDir(currentDirId as number);
  const [items, setItems] = useState<Dir[]>([]);

  useEffect(() => {
    if (!dir && !currentDirId) {
      setItems([]);
    }
    if (dir && dir.path) {
      setItems(dir.path);
    }
  }, [dir, currentDirId]);

  if (!dir) {
    return (
      <Breadcrumbs>
        <Link className={classes.link} to={config.myDiskPath}>
          <FolderIcon className={classes.icon} />
          <Typography color="textPrimary">{t('My Disk')}</Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  return (
    <Breadcrumbs maxItems={4} aria-label="breadcrumb">
      <Link className={classes.link} to={config.myDiskPath}>
        <FolderIcon className={classes.icon} />
        <Typography variant="inherit">{t('My Disk')}</Typography>
      </Link>
      {items.map((item: Dir) =>
        Number(item.id) === Number(currentDirId) ? (
          <div key={item.id} className={classes.link}>
            <Typography color="textPrimary">{dir.title}</Typography>
          </div>
        ) : (
          <Link
            key={item.id}
            className={classes.link}
            to={`/directory/${item.id}`}>
            <Typography variant="inherit">{item.title}</Typography>
          </Link>
        )
      )}
    </Breadcrumbs>
  );
}
