import React from 'react';
import { Resource } from '../../modules/resources';
import {
  compareDates,
  compareValues,
  localeCompare,
  Direction,
  useSortable
} from '../../utils/use-sortable';
import { SortableContext } from './utils/sortable.context';
import { applyFilters, Filters } from '../../core';

type SortableAttributes = 'name' | 'type' | 'createdAt' | undefined;
type CompareFunction = (a: Resource, b: Resource) => number;
const sortableMap = new Map<SortableAttributes, CompareFunction>([
  ['name', (a, b) => localeCompare(a, b, 'title')],
  ['type', (a, b) => compareValues(a, b, 'type')],
  ['createdAt', (a, b) => compareDates(a, b, 'createdAt')]
]);

const MyDiskProviders: React.FC = ({ children }) => {
  const sortable = useSortable<SortableAttributes>({
    id: 'my-disk',
    field: applyFilters(Filters.directories.defaultSortableField, undefined),
    direction: applyFilters(
      Filters.directories.defaultSortableDirection,
      undefined
    )
  });
  const sort = React.useCallback(
    (resources: Resource[]) =>
      resources.sort((a, b) => {
        if (!sortable.field) {
          return 0;
        }

        const dir = sortable.direction === Direction.ASC ? 1 : -1;
        const compareFunc = sortableMap.get(sortable.field);

        return compareFunc ? compareFunc(a, b) * dir : 0;
      }),
    [sortable.field, sortable.direction]
  );

  return (
    <SortableContext.Provider value={{ sortable, sort }}>
      {children}
    </SortableContext.Provider>
  );
};

export default MyDiskProviders;
