import React from 'react';
import { useUserHasRoles } from '../hooks';
import { ROLES } from '../constants';

interface Props {
  roles: string[];
  fallback?: React.ComponentType;
}

const HasRole: React.FC<Props> = ({
  roles = [],
  children,
  fallback: Fallback = null
}) => {
  const grantAccess = useUserHasRoles(roles);

  if (grantAccess) {
    return children as React.ReactElement;
  }

  if (Fallback) {
    return <Fallback />;
  }

  return null;
};

const SuperAdminRole: React.FC<Pick<Props, 'fallback'>> = ({
  children,
  ...rest
}) => (
  <HasRole {...rest} roles={[ROLES.ROLE_SUPER_ADMIN]}>
    {children}
  </HasRole>
);

const EmployeeRole: React.FC<Pick<Props, 'fallback'>> = ({
  children,
  ...rest
}) => (
  <HasRole {...rest} roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_EMPLOYEE]}>
    {children}
  </HasRole>
);

const AdminRole: React.FC<Pick<Props, 'fallback'>> = ({
  children,
  ...rest
}) => (
  <HasRole
    {...rest}
    roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_EMPLOYEE, ROLES.ROLE_ADMIN]}>
    {children}
  </HasRole>
);

export { HasRole, AdminRole, EmployeeRole, SuperAdminRole };
