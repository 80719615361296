import React, { memo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { useTranslation } from 'utils/translation';
import { useDirQuery } from '../../../../modules/dirs/hooks';
import FolderIcon from '@material-ui/icons/Folder';
import { Dir } from '../../../../modules/dirs';
import { Loader } from '../../../../components';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiBreadcrumbs-separator': {
        marginLeft: '3px',
        marginRight: '3px',
        fontSize: '0.75em',
        lineHeight: 1
      },

      '& .MuiTypography-root': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }
    },
    icon: {
      marginLeft: '4px',
      marginRight: '4px'
    }
  })
);

type Breadcrumb = {
  id: number;
  title: string;
};

function ReadonlyBreadcrumbs({ id }: { id: Dir['id'] }) {
  const classes = useStyles();
  const { t } = useTranslation('navigation');
  const { data, isLoading } = useDirQuery(id, {
    enabled: Boolean(+id)
  });
  const items = (data && data.path) || [];

  const rootItem = (
    <Typography variant="inherit">
      <FolderIcon className={classes.icon} fontSize="inherit" />
      {t('My Disk')}
    </Typography>
  );

  if (isLoading) {
    return <Loader size={14} />;
  }

  if (items.length === 0) {
    return <Breadcrumbs className={classes.root}>{rootItem}</Breadcrumbs>;
  }

  return (
    <Breadcrumbs className={classes.root} maxItems={4} aria-label="breadcrumb">
      {rootItem}
      {items.map((item: Breadcrumb) => (
        <Typography variant="inherit">
          <FolderIcon className={classes.icon} fontSize="inherit" />
          {item.title}
        </Typography>
      ))}
    </Breadcrumbs>
  );
}

export default memo(ReadonlyBreadcrumbs);
