import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '@material-ui/core';
import { useTranslation } from 'utils/translation';
import config from 'config/app';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10000
  },
  content: {
    backgroundColor: colors.orange[600],
    color: theme.palette.white,
    padding: theme.spacing(0.25, 3),
    textTransform: 'uppercase',
    textAlign: 'center',
    borderRadius: '0 0 6px 6px'
  }
}));

const TestWarning = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isTestEnv = config.isTestEnv;

  if (!isTestEnv) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="caption" color="inherit">
          {t('Test Data')}
        </Typography>
      </div>
    </div>
  );
};

export default TestWarning;
