import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Paper, Hidden, IconButton } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import NavBarActions from './components/NavBarActions';
import { DirectoryTree } from './components/DirectoryTree';
import NavigationListItem from '../../../../components/Navigation/components/NavigationListItem';
import CloseIcon from '@material-ui/icons/Close';
import { BasketNavItem } from './components/Basket/BasketNavItem';
import { ReportNav } from './components/Reports/ReportNav';
import { useTranslation } from 'utils/translation';
import { PageListItem } from '../../../../components/Navigation/Navigation';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ROLES } from '../../../../modules/auth';
import { ReactComponent as UsersIcon } from '../../../../assets/icons/users.svg';
import { ReactComponent as DnsIcon } from '../../../../assets/icons/dns.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings.svg';
import { ReactComponent as HelpIcon } from '../../../../assets/icons/help.svg';
import { useAppSettings } from '../../../../modules/ui/hooks';
import { applyFilters, Filters } from '../../../../core';
import { Theme } from '../../../../theme';
import { useCurrentUser } from '../../../../modules/auth/hooks';
import config from 'config/app';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% - 64px)',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      position: 'fixed'
    }
  },
  content: {
    flexGrow: 1,
    paddingTop: 0
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {},
  treeContainer: {
    maxHeight: '50vh',
    position: 'relative',
    overflow: 'hidden'
  },
  mobileNavClose: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1100
  }
}));

type ItemsMap = {
  [key: string]: React.ReactElement | null;
};

const NavBar: React.FC<any> = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const router = useRouter();
  const { settings } = useAppSettings();
  const user = useCurrentUser();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    // eslint-disable-next-line
  }, [router.location.pathname]);

  const pathname = router.location.pathname || '';
  const isOpen = pathname === '/' || pathname.startsWith('/directory');

  const itemsMap = applyFilters<ItemsMap>(Filters.navbar.items, {
    reports: <ReportNav />,
    users: (
      <PageListItem
        page={{
          title: t(applyFilters(Filters.navbar.usersTitle, 'Users')),
          href: '/users',
          icon: UsersIcon,
          authRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_EMPLOYEE]
        }}
      />
    ),
    disk: (
      <NavigationListItem
        title={t(applyFilters(Filters.navbar.myDiskTitle, 'My Disk'))}
        href={config.myDiskPath}
        icon={DnsIcon}
        open={isOpen}>
        <DirectoryTree />
      </NavigationListItem>
    ),
    notifications: (
      <PageListItem
        page={{
          title: settings.feedback.name || 'Notifications',
          href: '/notifications',
          icon: NotificationsIcon,
          authRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_EMPLOYEE]
        }}
      />
    ),
    basket: <BasketNavItem />,
    settings: (
      <PageListItem
        page={{
          title: applyFilters(Filters.navbar.settingsTitle, 'Settings'),
          href: '/settings',
          icon: SettingsIcon,
          authRoles: [ROLES.ROLE_SUPER_ADMIN]
        }}
      />
    ),
    help: settings.helpPageUrl ? (
      <PageListItem
        page={{
          title: 'Help',
          href: settings.helpPageUrl,
          icon: HelpIcon,
          external: true
        }}
      />
    ) : null
  });

  const items = applyFilters(
    Filters.navbar.itemsOrder,
    ['reports', 'disk', 'basket', 'notifications', 'users', 'settings', 'help'],
    { user }
  );

  const navbarContentBefore = applyFilters(Filters.navbar.content.before, null);
  const navbarContentAfter = applyFilters(Filters.navbar.content.after, null);
  const navbarExtraItems = applyFilters(Filters.navbar.extraItems, null);

  const navbarContent = (
    <div className={classes.content}>
      {navbarContentBefore}
      <nav className={classes.navigation}>
        {items.map(id => (
          <React.Fragment key={id}>{itemsMap[id] || null}</React.Fragment>
        ))}
        {navbarExtraItems}
      </nav>
      {navbarContentAfter}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            <div className={classes.mobileNavClose}>
              <IconButton color="inherit" onClick={onMobileClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <NavBarActions />
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square>
          <NavBarActions />
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
