import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import PublicLayout from './layouts/Public';
import DashboardLayout from './layouts/Dashboard';
import MyDiskView from './views/MyDisk';
import SearchView from './views/MyDisk/Search';
import { AuthGuard, GuestGuard } from './components';
import route from './utils/routes';
import { applyFilters, Filters } from './core';
import { RouteConfig } from 'react-router-config';
import appConfig from 'config/app';

export const generateRoutes = (
  config: any,
  extraRoutes = [],
  extraPublicRoutes = []
): RouteConfig[] => [
  {
    path: route('/auth'),
    component: (props: any) => (
      <GuestGuard>
        <AuthLayout {...props} />
      </GuestGuard>
    ),
    routes: [
      {
        path: route('/auth/login'),
        exact: true,
        component: lazy(() => import('./views/Login'))
      },
      {
        path: route('/auth/password-reset'),
        exact: true,
        component: lazy(() => import('./views/PasswordReset'))
      },
      {
        path: route('/auth/password-reset/:token'),
        component: lazy(() => import('./views/PasswordReset'))
      },
      {
        component: () => <Redirect to="/errors/404" />
      }
    ]
  },
  {
    path: route('/errors'),
    component: ErrorLayout,
    routes: [
      {
        path: route('/errors/401'),
        exact: true,
        component: lazy(() => import('./views/Error401'))
      },
      {
        path: route('/errors/404'),
        exact: true,
        component: lazy(() => import('./views/Error404'))
      },
      {
        path: route('/errors/500'),
        exact: true,
        component: lazy(() => import('./views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/404" />
      }
    ]
  },
  {
    path: route('/public'),
    component: (props: any) => <PublicLayout {...props} />,
    routes: extraPublicRoutes
  },
  {
    route: '*',
    component: (props: any) => (
      <AuthGuard>
        <DashboardLayout {...props} />
      </AuthGuard>
    ),
    routes: [
      {
        path: route('/'),
        exact: true,
        component: applyFilters(Filters.app.rootComponent, MyDiskView)
      },
      {
        path: route(appConfig.myDiskPath),
        exact: true,
        component: MyDiskView
      },
      {
        path: route('/search/:search'),
        exact: true,
        component: SearchView
      },
      {
        path: route('/directory/:id(\\d+)'),
        exact: true,
        component: MyDiskView
      },
      {
        path: route('/directory/:id(\\w+)'),
        exact: true,
        component: () => <Redirect to="/" />
      },
      {
        path: route('/users'),
        exact: true,
        component: lazy(() => import('./views/Users'))
      },
      {
        path: route('/users/:tab'),
        component: lazy(() => import('./views/Users'))
      },
      {
        path: route('/user/:id'),
        component: lazy(() => import('./views/UserDetails'))
      },
      {
        path: route('/notifications'),
        exact: true,
        component: lazy(() => import('./views/Feedback'))
      },
      {
        path: route('/settings/:tab/:extra'),
        component: lazy(() => import('./views/Settings'))
      },
      {
        path: route('/settings/:tab'),
        component: lazy(() => import('./views/Settings'))
      },
      {
        path: route('/settings'),
        component: lazy(() => import('./views/Settings'))
      },
      {
        path: route('/report/:id/:state'),
        component: lazy(() => import('./views/Reports'))
      },
      {
        path: route('/report/:id'),
        component: lazy(() => import('./views/Reports'))
      },
      ...extraRoutes,
      {
        component: () => <Redirect to="/errors/404" />
      }
    ]
  }
];
