import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Theme, Toolbar } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  toolbar: {
    padding: 0
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  tabLabel: {}
}));

interface TabPanelProps {
  children?: React.ReactNode;
  className?: string;
  dir?: string;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" align="left" {...other}>
      <Box>{children}</Box>
    </Typography>
  );
};

type Props = {
  defaultIndex?: number;
  tabs: string[];
  contents: React.ReactElement[];
};

const TabsComponent: React.FC<Props> = ({
  defaultIndex = 0,
  tabs,
  contents
}) => {
  const [index, setIndex] = useState<number>(defaultIndex);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar} disableGutters>
        <Tabs
          value={index}
          variant="standard"
          onChange={(event, tab) => setIndex(tab)}>
          {tabs.map(item => (
            <Tab key={item} label={item} className={classes.tabLabel} />
          ))}
        </Tabs>
      </Toolbar>
      <SwipeableViews
        index={index}
        ignoreNativeScroll={false}
        disabled
        onChangeIndex={setIndex}>
        {React.Children.map(contents, (child, i) => (
          <TabPanel className={classes.content} value={i} index={i}>
            {child}
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
};

export default TabsComponent;
