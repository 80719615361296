import React from 'react';
import clsx from 'clsx';
import { IconButton, Menu, Theme, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    color: theme.palette.text.primary
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  }
}));

const MenuActions: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
};

const Item: React.FC<any> = ({ className, ...props }) => {
  const classes = useStyles();

  return <MenuItem {...props} className={clsx(classes.item, className)} />;
};

const Icon: React.FC<{ icon: any }> = ({ icon: Icon, ...props }) => {
  const classes = useStyles();

  return <Icon {...props} className={clsx(classes.icon)} />;
};

export default Object.assign(MenuActions, {
  Item,
  Icon
});
