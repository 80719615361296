import React, { useState } from 'react';
import clsx from 'clsx';
import { Dir } from '../index';
import { useQuery } from 'react-query';
import http from 'utils/http';
import { useTranslation } from 'utils/translation';
import {
  List,
  ListItem,
  Box,
  Typography,
  makeStyles,
  FormControl,
  TextField,
  Chip
} from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import FolderIcon from '@material-ui/icons/Folder';
import { Theme } from '../../../theme';
import { useDirQuery } from '../hooks';
import { Loader } from '../../../components';
import ReadonlyBreadcrumbs from '../../../views/MyDisk/components/Breadcrumbs/ReadonlyBreadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  item: {},
  selected: {
    fontWeight: 'bold'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  }
}));

type ItemProps = {
  onClick?(item: Pick<Dir, 'title' | 'id'>): void;
  isSelected(item: Pick<Dir, 'id'>): boolean;
  expanded?: boolean;
  filter?(item: Pick<Dir, 'id'>): boolean;
};

const DirectoryListItem: React.FC<
  Pick<Dir, 'id' | 'title' | 'childrenCount'> & ItemProps
> = ({
  id,
  title,
  childrenCount,
  onClick,
  isSelected,
  expanded = false,
  filter
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(expanded);

  const handleItemClick = () => {
    onClick && onClick({ id, title });
  };

  return (
    <>
      <ListItem dense>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box
            className={classes.item}
            display="flex"
            flexDirection="row"
            alignItems="center">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              onClick={() => setOpen(!open)}>
              {childrenCount > 0 ? (
                <>
                  {open ? (
                    <KeyboardArrowDown fontSize="small" />
                  ) : (
                    <KeyboardArrowRight fontSize="small" />
                  )}
                </>
              ) : (
                <div style={{ width: 20 }} />
              )}
            </Box>
            <Typography
              className={clsx({
                [classes.title]: true,
                [classes.selected]: isSelected({ id })
              })}
              variant="body2"
              onClick={handleItemClick}>
              <FolderIcon className={classes.icon} />
              {title}
            </Typography>
          </Box>
          <Box>
            {open && (
              <DirectoryList
                parentId={id}
                onItemClick={onClick}
                isItemSelected={isSelected}
                filter={filter}
              />
            )}
          </Box>
        </Box>
      </ListItem>
    </>
  );
};

const DirectoryList: React.FC<{
  parentId?: Dir['id'];
  onItemClick: ItemProps['onClick'];
  isItemSelected: ItemProps['isSelected'];
  filter: ItemProps['filter'];
}> = ({ parentId, onItemClick, isItemSelected, filter }) => {
  const { data, isLoading } = useQuery(
    `app_directories_${parentId || 'root'}`,
    () => {
      return http.get<never, { data: Dir[] }>('/api/directory/', {
        params: {
          perPage: 1000,
          parentId
        }
      });
    },
    {
      refetchInterval: false
    }
  );

  if (isLoading) {
    return null;
  }

  let items = (data && data.data) || [];

  if (filter) {
    items = items.filter(filter);
  }

  return (
    <List>
      {items.map(item => (
        <DirectoryListItem
          {...item}
          key={item.id}
          onClick={onItemClick}
          isSelected={isItemSelected}
          filter={filter}
        />
      ))}
    </List>
  );
};

type Props = {
  placeholder?: string;
  value?: Nullable<Pick<Dir, 'id'>>;
  onChange?(dir: Nullable<Dir>): void;
  filter?(dir: Dir): boolean;
  error?: boolean;
  fullWidth?: boolean;
  expanded?: boolean;
};

const DirectoryPicker: React.FC<Props> = ({
  value,
  onChange,
  filter,
  error,
  fullWidth = true,
  expanded = false
}) => {
  const { t } = useTranslation('myDisk');
  const [active, setActive] = useState(expanded);
  const selectedId = (value && value.id) || '';

  const handleSelectedDirChange = (dir: Dir | any) => {
    if (!onChange) {
      return;
    }

    if (dir) {
      onChange(dir as Dir);
    } else {
      onChange(null);
    }

    setActive(false);
  };

  const isItemSelected = (item: Dir) => {
    return Boolean(value && Number(item.id) === Number(value.id));
  };

  if (!active) {
    return (
      <FormControl
        error={error}
        fullWidth={fullWidth}
        onClick={() => setActive(true)}>
        <Chip label={<ReadonlyBreadcrumbs id={Number(selectedId)} />} />
      </FormControl>
    );
  }

  return (
    <List>
      <DirectoryListItem
        id={0}
        title={t('My Disk')}
        childrenCount={1}
        onClick={handleSelectedDirChange}
        isSelected={isItemSelected}
        expanded={expanded}
        filter={filter}
      />
    </List>
  );
};

export default DirectoryPicker;
