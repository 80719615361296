import React from 'react';
import { useSelector } from 'react-redux';
import { selectSwitchedUsername } from '../selectors';
import { useUserSwitchActions } from '../hooks';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Box,
  Button,
  Card,
  Theme,
  Typography,
  CardContent,
  CardActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Trans } from 'react-i18next';
import { useTranslation } from 'utils/translation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: 10000
  },
  content: {
    paddingBottom: 0
  }
}));

const SwitchedUserNotification = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const switchedUser = useSelector(selectSwitchedUsername);
  const { resetUser } = useUserSwitchActions();

  if (switchedUser) {
    return (
      <Box className={classes.root}>
        <Card raised>
          <CardContent className={classes.content}>
            <Typography variant="body2">
              <Trans i18nKey="acting_as_user">
                You are acting as user <strong>{{ switchedUser }}</strong>
              </Trans>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => resetUser()}>
              <ArrowBackIcon />
              {t('leave_switched_user_account')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    );
  }

  return null;
};

export default SwitchedUserNotification;
